/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-regular-webfont.0beea363d5531789adb5.woff2")
    format("woff");
} */
/* body {
  font-family: "Proxima Nova";
  letter-spacing: "-0.5px";
  src: url("./fonts/proximanova-regular-webfont.0beea363d5531789adb5.woff2")
    format("woff");
} */

/* @layer base {
  html {
    font-family: Proxima Nova, system-ui, sans-serif;
  }
} */
@layer base {
  html {
    font-family: "Proxima Nova", "DM Sans", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    letter-spacing: "-0.5px";
    src: url("./fonts/proximanova-regular-webfont.0beea363d5531789adb5.woff2")
      format("woff");
  }

  body {
    font-family: "Proxima Nova", "DM Sans", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    letter-spacing: "-0.5px";
    src: url("./fonts/proximanova-regular-webfont.0beea363d5531789adb5.woff2")
      format("woff");
  }

  option {
    color: black;
  }
  .scrollbar-default {
    @apply scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100 scrollbar-track-rounded-full scrollbar-thumb-rounded-full;
  }
  /* Add this to your global CSS file or a module CSS file */

  .flip-container:hover .flipper,
  .flip-container.do-flip .flipper {
    transform: rotateY(180deg);
  }
  .flip-container {
    perspective: 1000px; /* Adjust as needed */
  }

  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
  }

  .flip-container:hover .flipper,
  .flip-container.do-flip .flipper {
    transform: rotateY(180deg);
  }

  #card-front,
  #card-back {
    backface-visibility: hidden;
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
  }

  #card-front {
    /* This ensures the front content is not mirrored */
    transform: rotateY(0deg);
  }

  #card-back {
    /* This ensures the back is initially hidden */
    transform: rotateY(180deg);
  }
  #card-back-content {
    /* transform: rotateY(180deg); */
    position: absolute;
    top: 0;
    left: 0;
  }
  /* h1 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 4.1rem;
    letter-spacing: -3px;
  } */
}

/* Custom class definition */

/* @import './additional-styles/range-slider.scss';
@import './additional-styles/toggle-switch.scss';
@import './additional-styles/utility-patterns.scss';
@import './additional-styles/theme.scss';
@import './additional-styles/flatpickr.scss'; */

/* @import 'additional-styles/flatpickr.scss'; */

/* @import '/src/css/additional-styles/utility-patterns.scss';
@import '/src/css/additional-styles/range-slider.scss';
@import '/src/css/additional-styles/toggle-switch.scss';
@import '/src/css/additional-styles/flatpickr.scss';
@import '/src/css/additional-styles/theme.scss'; */

/* #slide {
    position: absolute;

    bottom: -1000px;

    left:25%;

    right:25%;

   
    -webkit-animation: slide 0.5s forwards;

    -webkit-animation-delay: 2s;

    animation: slide 0.5s forwards;


}


@-webkit-keyframes slide {
    100% { bottom: 0;
 }

}


@keyframes slide {
    100% { bottom: 0;
 }

}
 */

/* @media all and (max-width:767px){body .tto-root{font-size:14px;
font-weight:400}
}
.legacy h1,#dialogRegion.legacy span.title,#dialogRegion.section_file .FnFtitle,#modalContainer .FnFtitle{font-weight:400;
font-size:30px;
color:#0077c5;
text-align:left;
position:relative}
@media all and (max-width:767px){.legacy h1,#dialogRegion.legacy span.title,#dialogRegion.section_file .FnFtitle,#modalContainer .FnFtitle{font-weight:400;
color:#6b6c72;
font-size:24px;
text-align:center}
}
.legacy h2{font-weight:400;
font-size:24px;
margin:0}
.legacy h3,.legacy #sectionTitle{font-weight:600;
font-size:20px;
margin:0;
text-align:left;
text-decoration:none}
@media all and (max-width:767px){.legacy h3,.legacy #sectionTitle{font-size:18px;
font-weight:700}
}
@media all and (max-width:767px){.legacy #sectionTitle{display:none}
}
.legacy h3 span{font-size:16px;
font-weight:400}
.legacy h4{font-weight:100}
.legacy strong,.legacy .bold,.legacy b{font-weight:700}
.legacy em,.legacy .italic{font-style:italic}
.legacy ul{list-style-type:disc;
padding-left:20px}
.legacy ul li{padding-top:8px}
.legacy p,.legacy li,.legacy table.grid td,.legacy table.gridV2 td{font-size:16px;
line-height:26px}
@media all and (max-width:767px){.legacy p,.legacy li,.legacy table.grid td,.legacy table.gridV2 td{font-size:14px}
}
.legacy p{margin:16px 0}
.legacy .screenid_prepare_state_home .deleteLink{padding-left:10px}
.legacy a{cursor:pointer;
text-decoration:none}
.legacy a.none,.legacy a.textLnk{text-decoration:underline}
@media all and (max-width:767px){.legacy a.none,.legacy a.textLnk{text-decoration:none}
}
.legacy input,.legacy textarea,.legacy select,.legacy select>option,.legacy button{font-weight:100}
@media all and (max-width:767px){.legacy input,.legacy textarea,.legacy select,.legacy select>option,.legacy button{font-weight:400}
}
.font_0{font-weight:700;
color:#393a3d;
background:#FF9}
.font_1{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#FF9}
.font_2{font-weight:700;
font-size:1em;
color:#393a3d;
background:#CFC}
.font_3{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#CFC}
.font_4{font-weight:700;
font-size:.9230769230769231em;
color:#FFF;
background:#8d9096}
.font_5{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#09F}
.font_6{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#09F}
.title{font-weight:700;
font-size:1.3846153846153846em;
color:#333}
.titleitalic{font-weight:700;
font-size:1.3846153846153846em;
color:#333}
.subtitle{font-weight:600;
font-size:1.0769230769230769em}
.question1{font-weight:700;
font-size:1.0769230769230769em;
color:#393a3d}
.bolditalic{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d}
.screenid_prepare_state_home .maintext{padding-right:10px;
vertical-align:text-top}
@media all and (max-width:767px){.screenid_prepare_state_home .maintext{padding-right:0;
vertical-align:text-top;
font-size:12px}
}
.screenid_prepare_state_home .mainBoldText{padding-right:10px;
vertical-align:text-top;
font-weight:700}
@media all and (max-width:767px){.screenid_prepare_state_home .mainBoldText{padding-right:0;
vertical-align:text-top;
font-weight:700;
font-size:12px}
}
.main{color:#393a3d}
.mainbold{font-weight:700;
color:#393a3d}
.mainitalic{font-style:italic;
color:#393a3d}
.mainbolditalic{font-weight:700;
font-style:italic;
color:#393a3d}
.caution{font-weight:700;
font-size:.9230769230769231em;
color:red}
.baldue{font-weight:700;
font-size:.9230769230769231em;
color:#888}
.survey1{font-weight:700;
color:#336}
.survey2{font-size:.9230769230769231em;
color:#666}
.username{font-weight:700;
font-size:.9230769230769231em;
color:#692B12}
.goodthing{font-weight:700;
font-size:.9230769230769231em;
color:#2cbc9b}
.important{font-weight:700;
color:#CE0000}
.example{font-weight:700;
font-size:.9230769230769231em;
color:#006}
.note{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d}
@media all and (max-width:767px){.note{font-style:italic}
}
.anchor{font-weight:700;
font-size:1.8461538461538463em;
color:#336}
.title24{font-weight:700;
font-size:1.8461538461538463em;
color:#36C}
.title20{font-weight:700;
font-size:1.5384615384615385em;
color:#36C}
.title20black{font-weight:700;
font-size:1.5384615384615385em;
color:#393a3d}
.title20gray{font-weight:700;
font-size:1.5384615384615385em;
color:#333}
.title18{font-weight:700;
font-size:1.3846153846153846em;
color:#336}
.title18black{font-weight:700;
font-size:1.3846153846153846em;
color:#393a3d}
.subtitle18{font-weight:700;
font-size:1.3846153846153846em;
color:#336}
.subtitle16{font-weight:700;
font-size:1.2307692307692308em;
color:#336}
.subtitle16blue{font-weight:700;
font-size:1.2307692307692308em;
color:#06F}
.subtitle14{font-weight:700;
font-size:1.0769230769230769em;
color:#336}
.body{color:#393a3d}
.body16{font-size:1.2307692307692308em;
color:#333}
.body14{font-size:1.0769230769230769em;
color:#333}
.body13{color:#393a3d}
.body11{font-size:.9230769230769231em;
color:#393a3d}
.body11gray{font-size:.9230769230769231em;
color:#8d9096}
.prioryear{font-size:.9230769230769231em;
color:#8d9096}
.question{font-weight:700;
font-size:1.0769230769230769em;
color:#393a3d}
.guideme{font-weight:700;
font-size:1.5384615384615385em;
color:#FFF;
background:#6C6}
.notetext{font-size:.9230769230769231em;
color:#393a3d}
.key{font-weight:700;
color:#393a3d}
.alert{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d;
background:#FF9}
#txtblk_00_0.alert{background:0 0;
color:red;
padding:10px 0 10px 0}
.alerttext{font-size:.9230769230769231em;
color:#393a3d}
.colh{font-weight:700;
font-size:.9230769230769231em;
color:#C60}
.mainorange{font-size:1em;
color:#C60}
.gridtitle{font-weight:700;
font-size:1.2307692307692308em;
color:#393a3d}
.subtotal{font-weight:700;
font-size:1.2307692307692308em;
color:#393a3d}
.tableh{font-weight:700;
font-size:.9230769230769231em;
color:#FFF}
.smallkey{font-weight:700;
font-size:.9230769230769231em;
color:#393a3d}
.fineprint{font-size:.7692307692307693em;
color:#666}
.formname{font-size:.9230769230769231em;
color:#666}
.status{font-size:.9230769230769231em;
color:#8d9096}
.hthcolh{font-weight:700;
font-size:.9230769230769231em;
color:#333}
.refund{font-weight:700;
font-size:1.0769230769230769em;
color:#36C}
.lndtblheadtitle{font-weight:700;
font-size:1em;
color:#393a3d;
display:inline-block;
margin:10px 10px 0 0}
.lndtblheaddesc{font-size:1em;
color:#505050}
.lndtblsubheadtitle{font-weight:700;
font-size:1em;
color:#393a3d}
.lndtblsubheaddesc{font-size:1em;
color:#393a3d}
.lndtblnote{font-size:.9230769230769231em;
color:#8d9096}
.lndtblcolhead{font-weight:700;
font-size:.9230769230769231em;
color:#505050}
.lndtblcolstatus{font-size:.9230769230769231em;
color:#606060}
.legacy a.lndtblcolstatus{text-decoration:underline}
.lndtblmissinginfo{font-size:.9230769230769231em;
color:#2cbc9b}
.bigmoneygreen{font-size:1.3846153846153846em;
color:#2cbc9b}
.bigmoneyblack{font-size:1.3846153846153846em;
color:#333}
.bigmoneyred{font-size:1.3846153846153846em;
color:#E31B23}
.bigmoneygray{font-size:1.3846153846153846em;
color:#666}
.boldmaindarkblue{font-weight:700;
font-size:1em;
color:#1E2C5D}
.bodygreenstrong{font-weight:700;
font-size:1em;
color:#2cbc9b}
.bodygreen{font-size:1em;
color:#2cbc9b}
.guidemebtnsmall{font-size:.9230769230769231em;
color:#1763AF}
.guidemebtnlarge{font-size:1.2307692307692308em;
color:#1763AF}
.strikeout{text-decoration:line-through}
.lndtbltabs{font-weight:700;
font-size:.9230769230769231em;
color:#FFF}
.tablndingsubhead{font-weight:700;
font-size:1.2307692307692308em;
color:#FFF}
.tablndingtext{font-size:1em;
color:#FFF}
.tablndingtextbold{font-weight:700;
font-size:1em;
color:#FFF}
.tablndinghead{font-weight:700;
font-size:1.8461538461538463em;
color:#004102}
.tablndinglink{font-size:.9230769230769231em;
color:#C7C8C9}
.trmmachead{font-weight:700;
font-size:1.2307692307692308em;
color:#6D6D6D}
.trmmacsubsection{font-weight:700;
font-size:1.0769230769230769em;
color:#6D6D6D}
.trmmacsub{font-size:1em;
color:#6A6A6A}
.credential{font-size:1em;
color:#8d9096}
.statuspending{font-weight:700;
font-size:1.0769230769230769em;
color:#06C}
.statusaccepted{font-weight:700;
font-size:1.0769230769230769em;
color:#390}
.statusrejected{font-weight:700;
font-size:1.0769230769230769em;
color:#C00}
.statusvisited{font-weight:700;
font-size:1.0769230769230769em;
color:#C60}
.yourreturn{font-weight:700;
font-size:1.0769230769230769em;
color:#666}
._leftnavbkgd{font-weight:700;
font-size:1.8461538461538463em;
color:#F5FCFF}
._hypertext{font-size:1em;
color:#06F}
._buttonctrl{font-weight:700;
font-size:1.0769230769230769em;
color:#06F}
._smallbuttonctrl{font-weight:700;
font-size:1.0769230769230769em;
color:#06F}
._refundmonitor{font-weight:700;
font-size:1.0769230769230769em;
color:#F5FCFF}
.lightGrey{color:#8d9096}
.lightGreyStrong{color:#8d9096;
font-weight:700}
.largeLightGrey{color:#8d9096;
font-size:38px}
.lightGreen{color:#2cbc9b}
.lightGreenStrong{color:#2cbc9b;
font-weight:700}
.largeLightGreen{color:#2cbc9b;
font-size:38px}
.smallLabel{font-size:13px}
.smallTitle{font-size:24px;
color:#0077c5}
.statelandingtablebox{background-color:#E6FAFD;
padding:36px 24px;
position:relative;
border:rgba(61,185,241,.23) 1px solid;
border-radius:0;
background:-moz-linear-gradient(top,#fff 0,#eefaff 100%);
background:-webkit-gradient(left top,left bottom,color-stop(0,#fff),color-stop(100%,#eefaff));
background:-webkit-linear-gradient(top,#fff 0,#eefaff 100%);
background:-o-linear-gradient(top,#fff 0,#eefaff 100%);
background:-ms-linear-gradient(top,#fff 0,#eefaff 100%);
background:linear-gradient(to bottom,#fff 0,#eefaff 100%)}
.statelandingtablebox>*{z-index:3;
position:relative}
.statelandingtablebox:after{content:' ';
background-image:url();
background-repeat:repeat;
width:100%;
height:100%;
top:0;
left:0;
position:absolute;
z-index:0}
.white{position:relative;
padding:5px;
border:solid 1px #ccc;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#FFF}
.gray{position:relative;
padding:30px;
border:solid 1px #E0DEDE;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#F8F8F8;
background-image:-moz-linear-gradient(center top,#ede9e9 0,#f8f8f8 100%);
background-image:-webkit-gradient(linear,center top,center bottom,from(#ede9e9),to(#f8f8f8));
-pie-background:linear-gradient(#ede9e9,#f8f8f8)}
.blockgray{position:relative;
padding:5px;
border:solid 1px #EEE;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#F4F4F4}
.blockyellow{position:relative;
padding:5px;
border:solid 1px #EEE;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#FFC}
.solid_gray{position:relative;
padding:5px;
border:solid 1px #ccc;
border-left:0;
border-right:0;
background-color:#F4F4F4}
.gridtotals{position:relative;
padding:10px;
border:solid 1px #ccc;
background-color:#F8F8FF}
.landingtableheader{position:relative;
padding:0;
border:solid 2px #A9A9A9;
border-top:0;
border-left:0;
border-right:0}
.landingtablepycolheader{position:relative;
padding:0;
border-radius:5px 5px 0 0;
-moz-border-radius:5px 5px 0 0;
-webkit-border-radius:5px 5px 0 0;
background-color:#AEB1B9;
color:#FFF}
.landingtablecycolheader{position:relative;
padding:0;
border-radius:5px 5px 0 0;
-moz-border-radius:5px 5px 0 0;
-webkit-border-radius:5px 5px 0 0;
background-color:#7F8188;
color:#FFF}
.blue.gradient{position:relative;
padding:0;
border:solid 1px #7A8995;
border-radius:8px;
-moz-border-radius:8px;
-webkit-border-radius:8px;
background-color:#DBEDFC;
background-image:-moz-linear-gradient(center top,#fff 0,#c0dff8 3%,#fff 6%,#dbedfc 10%,#bdf0fd 50%,#dbedfc 72%,#fff 93%,#bddef9 97%,#fff 100%);
background-image:-webkit-gradient(linear,center top,center bottom,from(#fff),color-stop(.03,#c0dff8),color-stop(.06,#fff),color-stop(.1,#dbedfc),color-stop(.5,#bdf0fd),color-stop(.72,#dbedfc),color-stop(.93,#fff),color-stop(.97,#bddef9),to(#fff));
-pie-background:linear-gradient(#fff,#fff)}
.requiredfield{position:relative;
padding:1px;
background-color:#EB332D}
.nonrequiredfield{position:relative;
padding:1px;
background-color:#FFF}
.rule3solid{font-size:0;
border-top:solid 3px #ccc;
width:100%}
.rule2solid{font-size:0;
border-top:solid 2px #ccc;
width:100%}
.rule1solid{font-size:0;
border-top:solid 1px #ccc;
width:100%}
.rule1dashes{font-size:0;
border-top:dashed 1px #ccc;
width:100%}
.bulletPoint:before{content:"â€¢ "}

  @media all and (min-width:768px) and (max-width:991px){.legacy .container,#ttoContainer>#main.container{max-width:none}
}
body,h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{font-family:"Avenir Next forINTUIT",Avenir,Helvetica,Arial,sans-serif}
a,.btn-link{color:#037c8f}
.legacy a:not(.btn),.legacy .btn-link{font-weight:500;
color:#037c8f}
.legacy a:not(.btn):hover,.legacy .btn-link:hover{color:#0493a3}
.legacy a:not(.btn):focus,.legacy .btn-link:focus{color:#0493a3}
.legacy a:not(.btn):active,.legacy .btn-link:active{color:#05a4b5}
.legacy a:not(.btn):hover,.legacy .btn-link:hover,.legacy a:not(.btn):active,.legacy .btn-link:active,.legacy a:not(.btn):focus,.legacy .btn-link:focus{text-decoration:underline}
.legacy .btn{-webkit-border-radius:4px;
-webkit-background-clip:padding-box;
-moz-border-radius:4px;
-moz-background-clip:padding;
border-radius:4px;
background-clip:padding-box;
padding:10px 20px;
font-weight:500;
-webkit-transition:all 100ms;
-moz-transition:all 100ms;
-ms-transition:all 100ms;
-o-transition:all 100ms;
transition:all 100ms;
font-size:14px}
@media all and (max-width:767px){.legacy .btn{-webkit-border-radius:2px;
-webkit-background-clip:padding-box;
-moz-border-radius:2px;
-moz-background-clip:padding;
border-radius:2px;
background-clip:padding-box;
white-space:normal;
padding:10px}
}
.legacy .btn:not(.btn-default){border-color:transparent;
background-clip:inherit}
.legacy .btn:focus{text-decoration:none;
outline:0}
.legacy .btn:hover,.legacy .btn:active{text-decoration:none}
.legacy .btn:hover:not(.btn-default),.legacy .btn:active:not(.btn-default){border-color:transparent}
.legacy .btn:hover,.legacy .btn:focus{background-position:0 0}
.legacy .btn.btn-primary{text-shadow:none;
background:#037c8f}
.legacy .btn.btn-primary:hover{background:#05bdda}
.legacy .btn.btn-primary:active{background:#01252b}
.legacy .btn-group.open .dropdown-toggle.btn-primary{border-color:transparent;
background:#01252b}
.legacy .navbar{background-image:none}
.legacy #directDebitPayDate{font-size:1.25em}
@-webkit-keyframes pulsate{from{-webkit-transform:scale(1);
opacity:1}
50%{-webkit-transform:scale(1.2);
opacity:1}
to{-webkit-transform:scale(1);
opacity:1}
}
.legacy .pulsate{opacity:1;
-webkit-animation-name:pulsate;
-webkit-animation-duration:2s;
-webkit-animation-iteration-count:infinite}
.legacy .slider{margin-top:6px}
.legacy .slider.slider-horizontal .slider-track{height:15px;
border:2px solid #aaa;
background-color:#fff;
background-image:none}
.legacy .slider.slider-horizontal .slider-track .slider-selection{background-color:#00b800;
background-image:none;
border-radius:0!important;
box-shadow:none;
-webkit-box-shadow:none}
.legacy .slider.slider-horizontal .slider-track .slider-handle{background-color:#e6e6e6;
background-image:none;
border:2px solid #999;
border-radius:7px!important;
opacity:1;
height:22px;
width:22px}
.legacy .close{font-weight:700}
.legacy .close:hover{zoom:1;
filter:alpha(opacity=100);
-webkit-opacity:1;
-moz-opacity:1;
opacity:1;
color:#33b8f3}
.legacy .popover{-webkit-box-shadow:0 0 30px rgba(0,101,189,.5);
-moz-box-shadow:0 0 30px rgba(0,101,189,.5);
box-shadow:0 0 30px rgba(0,101,189,.5);
border:1px solid #ccc}
.legacy .popover.right .arrow{border-right-color:#ccc}
.legacy .popover.bottom .arrow{border-bottom-color:#ccc}
.legacy .popover.top .arrow{border-top-color:#ccc}
.legacy .popover.left .arrow{border-left-color:#ccc}
.legacy nav .popover-content{padding:0}
.legacy nav .popover-content #refund_explain .date{padding:2px 10px;
background-color:#eee;
font-size:12px}
.legacy nav .popover-content #refund_explain table>tbody>tr>td:first-child{font-size:20px}
.legacy nav .popover-content #refund_explain table>tbody>tr>td:nth-child(2){font-size:12px}
.legacy nav .popover-content #refund_explain table>tbody>tr>td:last-child{font-size:24px}
.legacy .table-hover>tbody>tr:hover>td,.legacy .table-hover>tbody>tr:hover>th{background-color:#DEEDF4;
cursor:pointer}
.legacy ul.nav-list li a{border-bottom:1px solid #ccc;
border-right:1px solid #ccc}
.legacy ul.nav-list li:first-child a{border-top:1px solid #ccc}
.legacy .nav>li>a:hover,.legacy .nav>li>a:focus{background-color:rgba(255,255,255,.1);
color:#fff}
.legacy #validation_alert.modal .modal-body{overflow:hidden}
.legacy #validation_alert.modal .modal-body p{display:inline-block}
.legacy #validation_alert.modal .modal-body button{float:right}
.legacy .modal-header img{float:left;
margin-right:10px}
.legacy .modal-backdrop.in{height:1000%}
.modal .modal-backdrop{z-index:auto}
.normal_font_label{display:inline;
font-weight:400}
#dialogRegion #contentRegion #contentLayer table .inputBtnLbl label{display:inline;
font-weight:400}
#dialogRegion.section_file.legacy label,#modalContainer label{display:inline;
font-weight:400}

  .loading-screen-hidden{display:none}
@media all and (max-width:991px){.native-app #main{margin-top:15px}
}
@media all and (min-width:992px){.native-app #main #main_right.subnav_fixed #dialogRegion{padding-top:84px}
}
@media all and (max-width:767px){.native-app #main #main_right #dialogRegion{padding:25px 15px;
min-height:0}
.native-app #main #main_right #dialogRegion .L-btn{float:none;
width:20%}
}
@media all and (max-width:480px){.native-app #main #main_right #dialogRegion .L-btn{width:30%;
margin-bottom:-20px;
margin-top:-5px}
}

   */
